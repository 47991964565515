'use client';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { UserMenu } from '@/components/UserMenu';
import { useGhostStore } from '@/legacy/store';
import { GhostCoinSpline } from '../GhostCoinSpline';

const CoinRightSide = (): JSX.Element => {
  const currentUser = useGhostStore((state) => state.user);
  const theme = useTheme();

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={theme.palette.common.black}
    >
      {Boolean(currentUser) && (
        <Box sx={{ position: 'absolute', top: 40, right: 80 }}>
          <UserMenu isDark />
        </Box>
      )}
      <GhostCoinSpline />
    </Box>
  );
};

export default CoinRightSide;
